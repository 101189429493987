import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Layout from "../../../../components/layout";
import OrderButton from "../../../../components/orderbutton";
import ProductTable from "../../../../components/productTable";
import SEO from "../../../../components/seo";
import { Col, Container, Row } from "react-bootstrap";
import Content from "../../../../content/produk/controller/multimedia/HUIDU HD-A6/Product-Page.json";
import ProductImage from "../../../../images/product/controller/multimedia/HUIDU HD-A6/Product-Summary-HUIDU-HD-A6.jpg";
import Datasheet from "../../../../content/produk/controller/multimedia/HUIDU HD-A6/datasheet-huidu-hd-a6.pdf";

const huiduhda6 = () => (
    <Layout>
        <SEO
            title={Content.seo.title}
            description={Content.seo.description}
            lang={Content.seo.lang}
        />
        <section id={"produk"} className={"section-bg"}>
            <Container>
                <div className={"section-title"}>
                    <h1>Multimedia HUIDU HD-A6</h1>
                    <Row>
                        <Col>
                            <Image
                                src={ProductImage}
                                fluid
                                alt={"Gambar modul"}
                            />
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Product Summary</h2>
                        </div>
                        <p>
                            HD-A6 adalah sistem kontrol LED untuk remote control
                            dan playback HD video untuk layar LED ber-PPI
                            tinggi.
                        </p>
                        <p>
                            <OrderButton
                                messageText={Content.messageText}
                                datasheet={Datasheet}
                            />
                        </p>
                    </Col>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Specification</h2>
                        </div>
                        <ProductTable
                            tableName={"LED Unit Cabinet"}
                            tableData={Content.specification}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
);

huiduhda6.propTypes = {
    siteTitle: PropTypes.string,
};

huiduhda6.defaultProps = {
    siteTitle: ``,
};
export default huiduhda6;
